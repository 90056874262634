import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const RecipesList = ({data}) => {
  const recipes = data.allContentfulRecipe.edges;

  return (
  <Layout>
    <SEO title="All Recipes" />
    <h1 className="py-5">List Recipes</h1>
    <div className="contentContainer">
      <div className="px-4">
      { recipes
            .filter(post => post.node.title.length > 0)
            .sort()
            .map(({ node: recipe }, index) => (
              <div key={recipe.id} className="group">
                <div className="px-2 py-4">
                <div>
                  <div className="px-2 py-4">
                    <Link to={`/${recipe.slug}`}>
                    <span className="author">{`${recipe.author}'s`}</span>
                        <h2>{recipe.title}</h2>
                    </Link>
                  </div>
                  <hr />
                </div>
                </div>
                <hr />
              </div>
            ))}
      </div>
    </div>
  </Layout>
  )
}

export default RecipesList

export const pageQuery = graphql`
  query RecipesListPageQuery {
    allContentfulRecipe {
      edges {
        node {
          id
          title
          slug
          tags
          author 
          addedDate(formatString: "MM-DD-YYYY")
        }
      }
    }
  }
`